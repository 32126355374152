const image0 = new URL("~/img/Procedural_Generator_Cpp/0.png?as=jpg", import.meta.url);
const image1 = new URL("~/img/Procedural_Generator_Cpp/1.png?as=jpg", import.meta.url);
const image2 = new URL("~/img/Procedural_Generator_Cpp/2.png?as=jpg", import.meta.url);
const image3 = new URL("~/img/Procedural_Generator_Cpp/3.png?as=jpg", import.meta.url);
const video = new URL("~/video/pcg.mp4", import.meta.url);

export default {
    name: "2D_Terrain_2",
    files: [
        {
            type: "image",
            title: "0.png",
            desc: "// Surface",
            width: 1640,
            height: 614,
            src: image0,
        },
        {
            type: "image",
            title: "1.png",
            desc: "// Tundra, forest and ocean biome",
            width: 1640,
            height: 614,
            src: image1,
        },
        {
            type: "image",
            title: "1.png",
            desc: "// Forest and jungle biome",
            width: 1640,
            height: 614,
            src: image2,
        },
        {
            type: "video",
            title: "showcase.mp4",
            desc: "// Showcase of functionality",
            width: 1640,
            height: 614,
            src: [image3, video],
        },
    ],
    code: `/**
* -----------------------------------------------------------
*    ___  ___    ______                 _        ___ 
*   |_  |/ _ \\  /_  __/__ ___________ _(_)__    |_  |
*  / __// // /   / / / -_) __/ __/ _ \`/ / _ \\  / __/ 
* /____/____/   /_/  \\__/_/ /_/  \\_,_/_/_//_/ /____/ 
* -----------------------------------------------------------
* 
* Procedural generation of surface and cave systems.
* Application allows user to change generation parameters
* to modify generation behaviour.
*
* Change can be seen in the real time.
*
* https://github.com/MatusSkerlik/ProceduralGeneratorCpp
*/
let Terrain_2D_2 = function() {

    let name   = "2D Terrain 2";
    let date   = "25/05/2022";
    let lang   = "c++";

    let Libraries = [
        "raylib"
    ];

    let Features = [
        "online_procedural_generation",
        "perlin_noise",
        "linear_interpolation",
        "cosine_interpolation",
        "constraint_satisfaction_problem",
        "cubic_spline",
        "agent_based_generation"
    ];
};
`
}
