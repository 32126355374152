import "../css/files.sass";
import "./utils";

const icon_zoom_in = new URL("~/img/zoom_in.svg", import.meta.url);
const icon_zoom_out = new URL("~/img/zoom_out.svg", import.meta.url);
const icon_zoom_actual = new URL("~/img/zoom_actual.svg", import.meta.url);
const icon_window_close = new URL("~/img/window_close.svg", import.meta.url);

/**
 * Interface to assets sidebar, which shows images of project
 * @param parent
 * @param bounding
 * @returns {{create: create}}
 */
let Files = function(parent, bounding)
{
    /**
     * Reference to images
     * @type {[[Number, HTMLElement]]}
     */
    let ref = [];

    /**
     * Asset window reference
     * @type {undefined|HTMLElement}
     */
    let window_ref = undefined;

    /**
     * Last scroll x
     * @type {number}
     */
    let scroll_x = 0;

    /**
     * Last scroll y
     * @type {number}
     */
    let scroll_y = 0;

    /**
     * if mouse is down
     * @type {boolean}
     */
    let is_down = false;

    /**
     * Offset from top left corner of window when dragging begins
     * @type {undefined|[Number, Number]}
     */
    let window_offset = undefined;

    if (!(parent instanceof HTMLElement))
    {
        throw Error("Illegal argument type.");
    }

    if (parent.childNodes.length > 0)
    {
        throw Error("Container must be empty.");
    }

    /**
     * Returns array of four values,
     *
     * First and second values represents
     * if its legal to move child into x and y
     * coordinate accordingly
     *
     * Third and forth values represents
     * legal x and y accordingly
     *
     * @param parent
     * @param child
     * @param x
     * @param y
     * @returns {[boolean, boolean, Number, Number]}
     */
    function is_move_illegal(child, parent, x, y)
    {
        let parent_rect = parent.getBoundingClientRect();
        let child_rect = child.getBoundingClientRect();

        return [
            (x < window.scrollX + parent_rect.x) ||
            (x + child_rect.width) > (window.scrollX + parent_rect.x + parent_rect.width)
            ,
            (y < window.scrollY + parent_rect.y) ||
            (y + child_rect.height) > (window.scrollY + parent_rect.y + parent_rect.height)
            ,
            (x < window.scrollX + parent_rect.x) ?
                window.scrollX + parent_rect.x :
                (window.scrollX + parent_rect.x + parent_rect.width) - child_rect.width
            ,
            (y < window.scrollY + parent_rect.y) ?
                window.scrollY + parent_rect.y :
                (window.scrollY + parent_rect.y + parent_rect.height) - child_rect.height
        ];
    }
    
    /**
     * Find optimal ratio for image
     * @param {[Number, Number]} size
     * @param {[Number, Number]} size_max
     * @returns {[Number, Number]}
     */
    function find_ratio(size, size_max)
    {
        let [size_x, size_y] = size;
        let [max_x, max_y] = size_max;

        while (((size_x / max_x) > 0.95) || ((size_y / max_y) > 0.95))
        {
            size_x = size_x - size_x * .01;
            size_y = size_y - size_y * .01;
        }

        return [size_x, size_y];
    }

    /**
     * Parse youtube video id
     * @param {string} url
     * @returns {string}
     */
    function youtube_parser(url){
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }

    let Files = document.createElement("div");
    Files.setAttribute("class", "Files");

    let FilesTitle = document.createElement("span");
    FilesTitle.setAttribute("class", "FilesTitle");
    FilesTitle.textContent = "Files";

    let FilesList = document.createElement("div");
    FilesList.setAttribute("class", "FilesList");

    Files.appendChild(FilesTitle);
    Files.appendChild(FilesList);
    parent.appendChild(Files);

    return {

        /**
         * Create assets
         * @param {string} type
         * @param {string} title
         * @param {string} description
         * @param {Number} width
         * @param {Number} height
         * @param {string} src
         */
        create: function (type, title, description, width, height, src)
        {
            let pos = ref.length;
            let AssetsImage = document.createElement("img");
            if (type == "image")
                AssetsImage.src = src;
            else if (type == "video")
                AssetsImage.src = src[0];
            else if (type == "youtube")
                AssetsImage.src = "https://img.youtube.com/vi/" + youtube_parser(src) + "/0.jpg";

            AssetsImage.setAttribute("class", "FilesImage");

            AssetsImage.addEventListener("click", function (){

                let max_width, max_height;

                if (window.innerWidth < 432)
                {
                    max_width = window.innerWidth;
                }
                else
                {
                    max_width = 432 > (window.innerWidth * 0.4) ? 432 : window.innerWidth * 0.4;
                }

                if (window.innerHeight < 432 + 152)
                {
                    max_height = window.innerHeight
                }
                else
                {
                    max_height = 432 + 152 > (window.innerHeight * 0.4) ? 432 + 152 : window.innerHeight * 0.4;
                }

                let Window = document.createElement("div");
                let WindowTitle = document.createElement("span");
                let CloseWindowIcon = document.createElement("img");
                let Header = document.createElement("div");
                let ZoomActualIcon = document.createElement("img");
                let ZoomInIcon = document.createElement("img");
                let ZoomOutIcon = document.createElement("img");
                let Actions = document.createElement("div");
                let ImageContainer = document.createElement("div");
                let ImageTitle = document.createElement("span");
                let ImageDescription = document.createElement("span");
                let Footer = document.createElement("div");
                Window.setAttribute("class", "Window");
                WindowTitle.setAttribute("class", "WindowTitle");
                Header.setAttribute("class", "Header");
                Actions.setAttribute("class", "Actions");
                ImageContainer.setAttribute("class", "ImageContainer");
                ImageTitle.setAttribute("class", "ImageTitle");
                ImageDescription.setAttribute("class", "ImageDescription");
                Footer.setAttribute("class", "Footer");

                CloseWindowIcon.src = icon_window_close;
                ZoomActualIcon.src = icon_zoom_actual;
                ZoomInIcon.src = icon_zoom_in;
                ZoomOutIcon.src = icon_zoom_out;

                let [w, h] = find_ratio([width, height], [max_width, max_height - 152]);

                Window.style.minWidth = max_width + "px";
                Window.style.maxWidth = max_width + "px";
                Window.style.width = max_width + "px";
                Window.style.minHeight = max_height + "px";
                Window.style.maxHeight = max_height + "px";
                Window.style.height = max_height + "px";
                WindowTitle.textContent = title;
                ImageContainer.style.width = max_width + "px";
                ImageContainer.style.minWidth = max_width + "px";
                ImageContainer.style.maxWidth = max_width + "px";
                ImageContainer.style.height = max_height - 152 + "px";
                ImageContainer.style.minHeight = max_height - 152 + "px";
                ImageContainer.style.maxHeight = max_height - 152 + "px";
                ImageDescription.textContent = description;

                let Image;
                switch (type){
                    case "image":
                        Image = document.createElement("img");
                        Image.src = src;
                        Image.style.width = String(w) + "px";
                        Image.style.height = String(h) + "px";
                        break;
                    case "video":
                        Image = document.createElement("video");
                        Image.src = src[1];
                        Image.controls = true;
                        Image.autoplay = true;
                        Image.width = w;
                        Image.height = h;
                        break;
                    case "youtube":
                        Image = document.createElement("iframe");
                        Image.src = src;
                        Image.style.width = String(w) + "px";
                        Image.style.height = String(h) + "px";
                        Image.setAttribute("allowFullScreen", "");
                        break;
                }

                let mousemove = function (e){
                    let x = window.scrollX + e.clientX - window_offset[0];
                    let y = window.scrollY + e.clientY - window_offset[1];

                    let [illegal_x, illegal_y, legal_x, legal_y] = is_move_illegal(Window, bounding, x, y);
                    console.log(x, y, legal_x, legal_y)
                    if (!illegal_x)
                    {
                        Window.style.left = x + "px";
                    }
                    else
                    {
                        Window.style.left = legal_x + "px";
                    }

                    if (!illegal_y)
                    {
                        Window.style.top = y + "px";
                    }
                    else
                    {
                        Window.style.top = legal_y + "px";
                    }
                };

                let mouseup = function (e){
                    if (is_down)
                    {
                        e.preventDefault();
                        is_down = false;
                        window_offset = [undefined, undefined];
                        window.removeEventListener("mousemove", mousemove);
                        window.removeEventListener("mouseup", mouseup);
                    }
                };

                ZoomActualIcon.addEventListener("click", function(){
                    Image.style.width = w + "px";
                    Image.style.height = h + "px";
                });

                ZoomInIcon.addEventListener("click", function(){
                    let rect = Image.getBoundingClientRect()
                    Image.style.width = rect.width + w * 0.1 + "px";
                    Image.style.height = rect.height + h * 0.1 + "px";
                });

                ZoomOutIcon.addEventListener("click", function(){
                    let rect = Image.getBoundingClientRect()
                    Image.style.width = rect.width - w * 0.1 + "px";
                    Image.style.height = rect.height - h * 0.1 + "px";
                });

                Window.addEventListener("click", function (){
                   Window.focus();
                });

                CloseWindowIcon.addEventListener("click", function(e){
                    e.preventDefault();
                    bounding.removeChild(Window);
                    window_ref = undefined;
                    scroll_x = 0;
                    scroll_y = 0;
                });

                Header.addEventListener("mousedown", function (e){
                    if (!is_down && (e.target === Header))
                    {
                        e.preventDefault();
                        is_down = true;
                        window_offset = [e.offsetX, e.offsetY];
                        window.addEventListener("mousemove", mousemove);
                        window.addEventListener("mouseup", mouseup);
                    }
                });

                Header.appendChild(WindowTitle);
                Header.appendChild(CloseWindowIcon);
                Window.appendChild(Header);
                Actions.appendChild(ZoomActualIcon);
                Actions.appendChild(ZoomInIcon);
                Actions.appendChild(ZoomOutIcon);
                Window.appendChild(Actions);
                ImageContainer.appendChild(Image);
                Window.appendChild(ImageContainer);
                Footer.appendChild(ImageDescription);
                Window.appendChild(Footer);
                bounding.appendChild(Window);

                if (window_ref instanceof HTMLElement)
                {
                    bounding.removeChild(window_ref);
                }

                let rect = AssetsImage.getBoundingClientRect();
                let window_rect = Window.getBoundingClientRect();

                let x = window.scrollX + rect.x - window_rect.width - 32;
                let y = window.scrollY + rect.y;

                let [illegal_x, illegal_y, legal_x, legal_y] = is_move_illegal(Window, bounding, x, y);

                if (illegal_x)
                {
                    x = legal_x;
                }

                if (illegal_y)
                {
                    y = legal_y
                }

                Window.style.left = x + "px";
                Window.style.top = y + "px";

                window_ref = Window;
            });

            ref.push([pos, AssetsImage]);
            FilesList.appendChild(AssetsImage);
        },

        clear: function()
        {
            ref = [];
            FilesList.textContent = "";

            if (window_ref instanceof HTMLElement)
            {
                bounding.removeChild(window_ref)
                window_ref = undefined;
            }
        }
    };
};

export default Files;
