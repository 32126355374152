let Starfield = function(canvas)
{

    if (!(canvas instanceof HTMLCanvasElement))
    {
        throw new Error("Illegal argument");
    }

    /**
     * True if animation in loop
     * @type {boolean}
     */
    let is_playing = false;

    /**
     *  Period of effect
     * @type {number}
     */
    let period = 1000;

    /**
     * Width of canvas
     * @type {number}
     */
    let width = window.innerWidth;

    /**
     * Height of canvas
     * @type {number}
     */
    let height = window.innerHeight;

    /**
     * Previous loop timestamp
     * @type {number}
     */
    let prev_time = 0;

    /**
     * Each star coordinates
     * @type {[[Number, Number, Number]]}
     */
    let stars = make_stars(2000);

    /**
     * Drawing context
     * @type {ImageBitmapRenderingContext | WebGLRenderingContext | WebGL2RenderingContext | CanvasRenderingContext2D | RenderingContext | OffscreenRenderingContext | OffscreenCanvasRenderingContext2D}
     */
    const context = canvas.getContext('2d');



    function set_canvas_size()
    {
        width = window.innerWidth
        height = window.innerHeight;
        canvas.width = width;
        canvas.height = height;
    }

    function make_stars(count)
    {
        let out = [];
        for (let i = 0; i < count; i++) {
            const s = {
                x: Math.random() * width - width / 2,
                y: Math.random() * height - height / 2,
                z: Math.random() * period
            };
            out.push(s);
        }
        return out;
    }

    function clear()
    {
        context.fillStyle = "#282C34";
        context.fillRect(0, 0, canvas.width, canvas.height);
    }

    function put_pixel(x, y, brightness)
    {
        const intensity = brightness * 255;
        const rgb = "rgb(" + intensity + "," + intensity + "," + intensity + ")";
        context.fillStyle = rgb;
        context.fillRect(x, y, 1, 1);
    }

    function move_stars(distance)
    {
        const count = stars.length;
        for (let i = 0; i < count; i++) {
            const s = stars[i];
            s.z -= distance;
            while (s.z <= 1) {
                s.z += period;
            }
        }
    }

    function tick(time) {
        let elapsed = time - prev_time;
        prev_time = time;

        move_stars(elapsed * 0.1);

        clear();

        const cx = width / 2;
        const cy = height / 2;

        const count = stars.length;
        for (let i = 0; i < count; i++) {
            const star = stars[i];

            const x = cx + star.x / (star.z * (1 / period));
            const y = cy + star.y / (star.z * (1 / period));

            if (x < 0 || x >= width || y < 0 || y >= height) {
                continue;
            }

            const d = star.z / period;
            const b = 1 - d * d;

            put_pixel(x, y, b);
        }

        if (is_playing)
        {
            requestAnimationFrame(tick);
        }
        else
        {
            clear();
        }
    }

    set_canvas_size();
    window.addEventListener("resize", set_canvas_size);

    return {

        start: function(){
            prev_time = 0;
            is_playing = true;
            requestAnimationFrame(tick);
        },

        stop: function(){
            is_playing = false;
        }
    };
};

export default Starfield;