const image0 = new URL("~/img/Chord_Browser/0.png?as=jpg", import.meta.url);
const image1 = new URL("~/img/Chord_Browser/1.png?as=jpg", import.meta.url);
const image2 = new URL("~/img/Chord_Browser/2.png?as=jpg", import.meta.url);
const image3 = new URL("~/img/Chord_Browser/3.png?as=jpg", import.meta.url);
const image4 = new URL("~/img/Chord_Browser/4.png?as=jpg", import.meta.url);
const image5 = new URL("~/img/Chord_Browser/5.png?as=jpg", import.meta.url);

export default {
    name: "Chord_Browser",
    files: [
        {
            type: "image",
            title: "chord_0.png",
            desc: "A#maj7 chord, which is played on 8, 7, 6 and 5th fret",
            width: 1080,
            height: 2280,
            src: image0,
        },
        {
            type: "image",
            title: "chord_1.png",
            desc: "C major chord, all time classic",
            width: 1080,
            height: 2280,
            src: image1,
        },
        {
            type: "image",
            title: "chord_2.png",
            desc: "Different version of C major chord",
            width: 1080,
            height: 2280,
            src: image2,
        },
        {
            type: "image",
            title: "chord_3.png",
            desc: "E minor played on high frets",
            width: 1080,
            height: 2280,
            src: image3,
        },
        {
            type: "image",
            title: "chord_4.png",
            desc: "",
            width: 1080,
            height: 2280,
            src: image4,
        },
        {
            type: "image",
            title: "chord_5.png",
            desc: "C major alias barre chord",
            width: 1080,
            height: 2280,
            src: image5,
        },
    ],
    code: `/**
* ----------------------------------------------------------
*   _______               _____                            
*  / ___/ /  ___  _______/ / _ )_______ _    _____ ___ ____
* / /__/ _ \\/ _ \\/ __/ _  / _  / __/ _ \\ |/|/ (_-</ -_) __/
* \\___/_//_/\\___/_/  \\_,_/____/_/  \\___/__,__/___/\\__/_/   
* ----------------------------------------------------------
* 
* Android mobile application for guitar chords browsing.
* When a user selects a chord, the app will generate 
* appropriate sound of the chord.
* 
* Highlight:
*     - downloads chords and saves them in local database
*     - implements dependency injection
*     - generates a sound based on the individual notes 
*       of the particular chord
*/
let ChordBrowser = function() {

    let name = "ChordBrowser";
    let date = "09/02/2020";
    let lang = "java";

    let Libraries = [
        "dagger",
        "room",
        "fasterxml"
    ];

    let Features = [
        "dependency_injection",
        "data_object_models",
        "sound_generation",
        "chord_view"
    ];
};

// --------------------- NOTES -----------------------

/**
* Dependency injection was implemented to improve 
* scalability of the project
*/
let DependencyInjection = new DependencyInjection();
DependencyInjection.init();
DependencyInjection.inject(new Poison());

/**
* Chord types are downloaded from source as xml
* and then converted to DAO objects, which are
* saved into a local database.
*/
let DataObjectModels = new DataObjectModels();

/**
* Sound is generated by playing each note of chord
* with a delay.
*/
let SoundGeneration = new SoundGeneration();

/**
* It draws appropriate guitar frets with dots and shows
* where the fingers should be.
*/
let ChordView = new ChordView();
`
}
