const image0 = new URL("~/img/Tizen_TV_Application/0.png?as=jpg", import.meta.url);
const image1 = new URL("~/img/Tizen_TV_Application/1.png?as=jpg", import.meta.url);
const image2 = new URL("~/img/Tizen_TV_Application/2.png?as=jpg", import.meta.url);
const image3 = new URL("~/img/Tizen_TV_Application/3.png?as=jpg", import.meta.url);


export default {
    name: "Open_TV",
    files: [
        {
            type: "image",
            title: "streams_0.png",
            desc: "// On the left we can see list of streams and on the right stream metadata.",
            width: 1920,
            height: 1080,
            src: image0,
        },
        {
            type: "image",
            title: "streams_1.png",
            desc: "// List of streams where selected stream is loading for play",
            width: 1920,
            height: 1080,
            src: image1,
        },
        {
            type: "image",
            title: "streams_2.png",
            desc: "// List of streams where selected stream is playing in small window",
            width: 1920,
            height: 1080,
            src: image2,
        },
        {
            type: "image",
            title: "fulscreen.png",
            desc: "// Fullscreen stream",
            width: 1920,
            height: 1080,
            src: image3,
        },
    ],
    code: `/**
* -----------------------------------------------------------
*   ____                  _______   __
*  / __ \\___  ___ ___    /_  __/ | / /
* / /_/ / _ \\/ -_) _ \\    / /  | |/ / 
* \\____/ .__/\\__/_//_/   /_/   |___/  
*     /_/                             
* -----------------------------------------------------------
*
* TizenOS (Samsung hospitality display) solution for 
* playing freetv streams.
* 
* Architecture: 
*     Backend:
*         - Stream metadata gathering 
*         - Technologies: 
*               Nette alike (customized REST API), 
*               SQLite, 
*               phpLiteAdmin
*     Frontend:
*         - Representation of metadata
*         - Technologies: 
*               HTML,
*               CSS,
*               Javascript
*/
let TV_Application = function() {

    let name = "Open_TV";
    let date = "21/07/2021";
    
    let Languages = [
        "javascript",
        "php",
        "sql"
    ];

    let Libraries = [
        "es2015",
        "guzzlehttp/guzzle",
        "nette/di",
        "nette/database",
        "nette/routing",
        "nette/http"
    ];
    
    let Features = [
        "metadata_download",
        "rest_api",
        "stream_playback"
    ];
};
    
// --------------------- NOTES -----------------------

/**
* Guzzle is an HTTP client. It was used to download 
* stream metadata.
*/
let GuzzleClient = new GuzzleClient();    
let result = GuzzleClient.open(
    "https://skerlik.sk",
    "GET"
);
if (result.status == 200) 
    console.log("Ooops, did not expect that !");

/**
* Nette dependency injection was used for scalability 
* of project.
* It really speeds up the development process.
*/
let NetteDI = new NetteDI();

/**
* Nette database layer suits the best for this project 
* since it simplifies database management. 
*/
let NetteDatabase = new NetteDatabase(); 
NetteDatabase.remove_all();
NetteDatabase.flush();

/**
* This package was chosen to simplify the 
* administration of client’s requests.
*/
let NetteHttp = new NetteHttp();
`
}
