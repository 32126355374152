const image0 = new URL("~/img/Procedural_Generator/0.jpg?as=jpg", import.meta.url);
const image1 = new URL("~/img/Procedural_Generator/1.jpg?as=jpg", import.meta.url);

export default {
    name: "2D_Terrain",
    files: [
        {
            type: "image",
            title: "at_the_beginning.png",
            desc: "// First solid base. Caves are expanding as depth is increasing, also dirt is decreasing and stone is increasing.",
            width: 1000,
            height: 1000,
            src: image0,
        },
        {
            type: "image",
            title: "after_some_work.png",
            desc: "// Interpolation in practise.",
            width: 1000,
            height: 1000,
            src: image1,
        },
    ],
    code: `/**
* -----------------------------------------------------------
*    ___  ___    ______                 _    
*   |_  |/ _ \\  /_  __/__ ___________ _(_)__ 
*  / __// // /   / / / -_) __/ __/ _ \`/ / _ \\
* /____/____/   /_/  \\__/_/ /_/  \\_,_/_/_//_/
* -----------------------------------------------------------
*
* Procedural generation of 2D cave systems.
* Implemented by value sampling of perlin noise.  
*/
let Terrain_2D = function() {

    let name = "2D Terrain";
    let date = "17/05/2021";
    let lang = "python";

    let Libraries = [
        "noise"
    ];

    let Features = [
        "procedural_generation",
        "perlin_noise",
        "linear_interpolation",
        "cosine_interpolation",
    ];
};

// --------------------- NOTES -----------------------

/** 
* Multiple layers of perlin noise were used to 
* produce differentmaterials (Ag, Cu, Au, dirt, ... ).
*/
let PerlinNoise = new PerlinNoise();
PerlinNoise.sample(pi, 3, 5, 8);


/**
* Interpolation was used to produce natural diffusion 
* of dirt and stone.
*
* It was also used in determination of cave sizes. 
* Caves are increasing in size when they are deeper.
*/
let Interpolation = new Interpolation();
`
}
