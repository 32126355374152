const image0 = new URL("~/img/Merraria/0.png?as=jpg", import.meta.url);
const image1 = new URL("~/img/Merraria/1.png?as=jpg", import.meta.url);
const image2 = new URL("~/img/Merraria/2.png?as=jpg", import.meta.url);
const image3 = new URL("~/img/Merraria/3.png?as=jpg", import.meta.url);
const video = new URL("~/video/merraria.mp4", import.meta.url);


export default {
    name: "Merraria",
    files: [{
        type: "image",
        title: "merraria_cave.png",
        desc: "// Excavation tunnel with gold, iron and silver ore.",
        width: 1024,
        height: 737,
        src: image0,
    },
        {
            type: "image",
            title: "merraria_cave_2.png",
            desc: "// One of the caves in cave system",
            width: 1024,
            height: 737,
            src: image1,
        },
        {
            type: "image",
            title: "merraria_surface.png",
            desc: "// Surface of Merraria, player used dirt block for building simple platforms",
            width: 1024,
            height: 737,
            src: image2,
        },
        {
            type: "video",
            title: "merraria_showcase.png",
            desc: "// Showcase of the game",
            width: 1024,
            height: 737,
            src: [image3, video],
        }],
    code: `/**
* -----------------------------------------------------------
*    __  ___                     _     
*   /  |/  /__ ___________ _____(_)__ _
*  / /|_/ / -_) __/ __/ _ \`/ __/ / _ \`/
* /_/  /_/\\__/_/ /_/  \\_,_/_/ /_/\\_,_/ 
* -----------------------------------------------------------
*  
* 2D game prototype with proceduraly generated world.
*
* Game includes:
*      Proceduraly generated world
*      Collision detection
*      Lighting system
*      Inventory
*      Crafting
*
* https://github.com/MatusSkerlik/PyTerraria
*/
let Merraria = function() {

    let name   = "Merraria";
    let date   = "02/04/2021";
    let lang   = "python";

    let Libraries = [
        "pygame",
        "numpy",
        "noise"
    ];

    let Features = [
        "procedural_generation",
        "lighting",
        "collision_detection",
        "inventory",
        "crafting"
    ];
};

// --------------------- NOTES -----------------------

/** 
* Procedural generation was implemented with multiple
* layers of 2D perlin noise.
*
* Linear and cosine interpolation was used to determine
* size of caves.
* 
* Terrain contains 3 ores ( Fe, Cu, Ag ) and multiple
* materials ( dirt, stone, ash, mud, ... ).
*/
let ProceduralGeneration = new ProceduralGenerator();
Canvas.render(
    ProceduralGeneration.generate_map(100, 100)
);


/**
* Light was implemented by usage of a smooth gradient.
* 
* System uses BFS algorithm to alter the light based 
* on distance from the base point.
*/
let Lighting = new Lighting();

/**
* Collision detection was implemented using
* separated axis theorem.
* 
* System is optimized to only notice the closest
* blocks surrounding the player. 
*/
let CollisionDetection = new CollisionDetection();

/**
* Inventory allows functions of crafting, adjusting
* position and quantity of items.
*/
let Inventory = new Inventory();
Inventory.add(new Tool(Tool.PICKAXE, 5, 100));
Inventory.add(new Ore(Ore.COPPER, 50));
`
}
