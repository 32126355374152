import "./vendor/codemirror/codemirror";
import "./vendor/codemirror/plugins/active_line";
import "./vendor/codemirror/modes/javascript";

import Terraria from "./projects/terraria";
import TV_App from "./projects/tv_app";
import Chord_Browser from "./projects/chord_browser";
import Procedural_Generator from "./projects/procedural_generator";
import Procedural_Generator_Cpp from "./projects/procedural_generator_cpp";

import Tabs from "./tabs";
import Files from "./files";
import Starfield from "./starfield";

/**
 * Bridge between tabs, assets and codemirror
 * @returns {{create: create}}
 * @constructor
 */
let Project = function (tabs, assets, codemirror){

    if (!(tabs instanceof HTMLElement))
    {
        throw new Error("Illegal argument");
    }

    if (!(assets instanceof HTMLElement))
    {
        throw new Error("Illegal argument");
    }

    if (!(codemirror instanceof HTMLElement))
    {
        throw new Error("Illegal argument");
    }

    /**
     * Tabs of project
     * @type {{create: create, remove: remove}}
     * @private
     */
    let _Tabs = Tabs(tabs);

    /**
     * Files of project
     * @type {{create: create}}
     * @private
     */
    let _Files = Files(assets, document.body);

    let _Code = CodeMirror(codemirror, {
        lineNumbers: true,
        styleActiveLine: true,
        matchBrackets: true,
        scrollbarStyle: "null",
        readOnly: false,
        lineWrapping: true,
        mode: "javascript",
    });

    /**
     * True if there was created at least one project
     * @type {boolean}
     */
    let initialized = false;

    return {
        /**
         * Insert new project into the portfolio
         * @param {string} title
         * @param {[{id: string, title: string, description: string, width: Number, height: Number}]} assets
         * @param {string} code
         */
        create: function(title, assets, code){

            _Tabs.create(title, function (){
                _Code.doc.setValue(code);

                // add assets
                _Files.clear();
                for (const asset of assets) {
                    _Files.create(...Object.values(asset));
                }
            });

            if (!initialized)
            {
                initialized = true;

                _Code.doc.setValue(code);
                _Files.clear();

                for (const asset of assets) {
                    _Files.create(...Object.values(asset));
                }
            }
        }
    };
}(
    document.getElementById("tabs"),
    document.getElementById("assets"),
    document.getElementById("code")
);

Project.create(...Object.values(Terraria));
Project.create(...Object.values(Procedural_Generator));
Project.create(...Object.values(Procedural_Generator_Cpp));
Project.create(...Object.values(TV_App));
Project.create(...Object.values(Chord_Browser));

let _Starfield = Starfield(
    document.getElementById("Starfield")
);
_Starfield.start();

let copyright = document.getElementById("Copyright");
copyright.innerHTML = "Matus Skerlik &copy; 2020, " + new Date().getFullYear();
