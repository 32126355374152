import "../css/tabs.sass";
import "./utils"

const icon_file_image = new URL("~/img/file_image.svg", import.meta.url);
const icon_file_text = new URL("~/img/file_text.svg", import.meta.url);
const icon_move_down = new URL("~/img/move_down.svg", import.meta.url);

/**
 * Interface from management of editor tabs
 * @param parent element where should tabs be created
 * @returns {{create: create, remove: remove}}
 */
let Tabs = function (parent) {

    /**
     * Reference to current tabs
     * @type {[[Number, String, HTMLElement]]}
     */
    let ref = [];

    /**
     * Reference to current hidden tabs
     * @type {[[Number, String, HTMLElement]]}
     */
    let ref_hidden = [];

    /**
     * Current focused tab position
     * @type {undefined}
     */
    let focused_pos = undefined;


    if (parent === undefined || !(parent instanceof HTMLElement))
    {
        throw Error("Illegal argument.");
    }

    if (parent.childNodes.length > 0)
    {
        throw Error("Tabs container must be empty.");
    }

    function get_ext(filename)
    {
        return filename.split('.').pop();
    }

    /**
     * Returns image source
     * @param title
     * @returns {string}
     */
    function image_src_from_title(title)
    {
        switch (get_ext(title))
        {
            case "png" || "jpg":
                return icon_file_image;
            default:
                return icon_file_text;
        }
    }

    function find_tab(at_pos)
    {
        let index = 0;
        for (const [pos, _, __] of ref) {
            if (pos === at_pos)
            {
                return index;
            }

            index++;
        }

        return undefined;
    }

    function get_tab(at_pos)
    {
        let index = find_tab(at_pos);
        if (index !== undefined)
        {
            return ref[index];
        }

        return [undefined, undefined, undefined, undefined];
    }

    /**
     * True if child is partly hidden in parent
     * @param parent
     * @param child
     * @returns {boolean}
     */
    function is_partly_hidden(parent, child)
    {
        let parent_rect = parent.getBoundingClientRect();
        let child_rect = child.getBoundingClientRect();

        return !((child_rect.x >= window.scrollX + parent_rect.x) &&
            ((child_rect.x + child_rect.width) <= (window.scrollX + parent_rect.x + parent_rect.width)) &&
            (child_rect.y >= window.scrollY + parent_rect.y) &&
            ((child_rect.y + child_rect.height) <= (window.scrollY + parent_rect.y + parent_rect.height)))
    }

    /**
     * Will check every tab for change in visibility
     */
    function validate_hidden()
    {
        ref_hidden = [];

        for (const [pos, filename, elm] of ref) {
            if (is_partly_hidden(Tabs, elm))
            {
                ref_hidden.push([pos, filename, elm]);
            }
        }

        if (ref_hidden.length === 0)
        {
            HiddenTabs.style.visibility = "hidden";
        }
        else
        {
            HiddenTabs.style.visibility = "visible";
        }
    }

    /**
     * Focus tab at specific position
     * @param pos
     */
    function focus(pos)
    {
        // in case user clicked on the same tab
        if (focused_pos === pos)
        {
            return;
        }

        let [_, __, current_focus] = get_tab(pos);
        if (current_focus instanceof HTMLElement)
        {
            // in case when we are adding first element into tabs
            if (focused_pos !== undefined)
            {
                let [_, __, last_focus] = get_tab(focused_pos);
                last_focus.classList.remove("focused");
            }

            current_focus.classList.add("focused");
            current_focus.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
            focused_pos = pos;
        }
    }

    let Tabs = document.createElement("div");
    Tabs.setAttribute("class", "Tabs");

    let TabList = document.createElement("div");
    TabList.setAttribute("class", "TabList");
    TabList.addEventListener("scroll", function(){
        validate_hidden();
    });

    let HiddenTabsIcon = document.createElement("img");
    HiddenTabsIcon.src = icon_move_down;

    let HiddenTabs = document.createElement("div");
    HiddenTabs.setAttribute("class", "HiddenTabs");
    // Expand List of hidden tabs
    HiddenTabs.addEventListener("click", function (e){

        // fire only if click is at self or icon
        if (e.target === HiddenTabs || e.target === HiddenTabsIcon) {

            let HiddenTabList = document.createElement("div");
            HiddenTabList.setAttribute("class", "HiddenTabList");

            for (const [pos, title, Tab] of ref_hidden) {

                let Container = document.createElement("div");
                Container.addEventListener("click", function () {
                    focus(pos);
                    let [_, __, ___, onclick] = get_tab(pos);
                    onclick.call(Tab);
                });

                let HiddenTabTitle = document.createElement("span");
                HiddenTabTitle.textContent = title;

                let HiddenTabIcon = document.createElement("img");
                HiddenTabIcon.src = image_src_from_title(title);

                Container.appendChild(HiddenTabIcon);
                Container.appendChild(HiddenTabTitle);
                HiddenTabList.appendChild(Container);
            }

            HiddenTabs.appendChild(HiddenTabList);

            // capture click and hide tabs
            let focusout = function () {
                HiddenTabs.removeChild(HiddenTabList);
                window.removeEventListener("click", focusout, {capture: true});
            };
            window.addEventListener("click", focusout, {capture: true});
        }
    });


    HiddenTabs.appendChild(HiddenTabsIcon);
    Tabs.appendChild(TabList);
    Tabs.appendChild(HiddenTabs);
    parent.appendChild(Tabs);

    window.addEventListener("resize", validate_hidden);

    return {

        /**
         * Create tab
         * @param title Tab title
         * @param onclick Function
         */
        create: function(title, onclick)
        {
            let pos = ref.length;

            let Tab = document.createElement("div");
            Tab.setAttribute("class", "Tab");
            Tab.setAttribute("tabindex", String(pos));

            if (onclick === undefined)
            {
                onclick = function(){
                    focus(pos);
                }
                Tab.addEventListener("click", onclick);
            }
            else if (onclick instanceof Function)
            {
                Tab.addEventListener("click", function(){
                    onclick.call(Tab);
                    focus(pos);
                });
            }
            else
            {
                throw Error("Illegal argument type");
            }

            let TabImage = document.createElement("img");
            TabImage.setAttribute("class", "TabImage")
            TabImage.src = image_src_from_title(title);

            let TamTitle = document.createElement("span");
            TamTitle.setAttribute("class", "TabTitle");
            TamTitle.textContent = title;


            ref.push([pos, title, Tab, onclick]);
            // add to the dom
            Tab.appendChild(TabImage);
            Tab.appendChild(TamTitle);
            TabList.appendChild(Tab);

            // focus first element
            if (focused_pos === undefined)
            {
                focus(0);
            }

            validate_hidden();
        },

        /**
         * Remove tab
         * @param at_pos
         */
        remove: function(at_pos)
        {
            parent.removeChild(ref[at_pos]);

            let i = find_tab(at_pos);
            ref[i] = undefined;

            if (at_pos === focused_pos)
            {
                focused_pos = undefined;
            }
        },
    };
}

export default Tabs
